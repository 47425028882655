import { Button, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Edit } from "../../firebase/firebasemethod";


function EditProduct() {
    let location = useLocation();
    // console.log(location.state);
    let [product, setProduct] = useState(location.state);

    const updateFN = () => {
        Edit(product, "Products", product.key).then(() => {
            alert("Data is successfully updated");
        }).catch(() => {
            alert("Something is wrong");
        })
    }

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField fullWidth label='Product ID' variant='standard' value={product.pId} onChange={e => setProduct({ ...product, pId: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField fullWidth label='Product Name' variant='standard' value={product.name} onChange={e => setProduct({ ...product, name: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField fullWidth label='Product Price' variant='standard' value={product.price} onChange={e => setProduct({ ...product, price: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField fullWidth label='Product Special Price' variant='standard' value={product.sPrice}  onChange={e => setProduct({ ...product, sPrice: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField fullWidth label='Product Description' value={product.desc} variant='standard' onChange={e => setProduct({ ...product, desc: e.target.value })} />
                    </Grid>
                </Grid>
            </Box>
            <Button onClick={updateFN}>Update data</Button>
        </>
    )
};


export default EditProduct;