import { Box, Grid } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import AddToCart from "./Components/AddToCart";
import Footer from "./Components/Footer";
import MyImage from "./Components/MyImage";
import Navbar from "./Components/Navbar";
import PageNavigation from "./Components/PageNavigation";


const SingleProduct = ()=>{
  const location = useLocation()
  const [data, setData] = useState('')
  let [cart, setCart] = useState([])
  const {id} = useParams()
  console.log(id)
// console.log(cart)
  

  useEffect(() => {
    setData(location.state)
  }, [])
  
 
  
  

  return(

      <>
      <Navbar />
    <Wrapper >
    <PageNavigation hamza={data.name}/>
    <Box sx={{ flexGrow: 1, mt:10 }} >
      <Container>
      <Grid container spacing={2}>
        <Grid item lg={6}>
          {/* product image */}
          <div className="product-images">
          {/* <MyImage imgs={data.image}/> */}
          <img src={data.image} width='80%'/>
          </div>
        </Grid>
        <Grid item lg={6}>
          {/* product data */}

          <div className="product-data">
            <h1>{data.name}</h1>
            <p className="product-data-price">Price:
            <del>
              
              {data.price}
              </del>
              </p>
              <p className="product-data-price product-data-real-price">
                Deal of the Day: {data.sPrice}
              </p>
              <h2>{data.desc}</h2>
              <hr />
              <div className="product-data-info">
                
                <h6>ID: <span>{data.pId}</span></h6>
                <h6>Brand: <span>Modestile</span></h6>
              </div>
              <hr />
              <AddToCart cart={cart} product={data}/>
          </div>
        </Grid>
      </Grid>
      </Container>
    </Box>
  </Wrapper>
  <Footer />
    </>
     )
  
}
const Images = styled.section`
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  gap: 1rem;
  .grid {
    flex-direction: row;
    justify-items: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
    /* order: 2; */
    img {
      max-width: 100%;
      max-height: 100%;
      background-size: cover;
      object-fit: contain;
      cursor: pointer;
      box-shadow: ${({ theme }) => theme.colors.shadow};
    }
  }
  .main-screen {
    display: grid;
    place-items: center;
    order: 1;
    img {
      max-width: 100%;
      height: auto;
      box-shadow: ${({ theme }) => theme.colors.shadow};
    }
  }
  .grid-four-column {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    display: flex;
    flex-direction: column;
    order: 1;
    .grid-four-column {
      grid-template-rows: 1fr;
      grid-template-columns: repeat(4, 1fr);
    }
  }
`;

const Wrapper = styled.section`
  .container {
    padding: 9rem 0;
  }
  .product-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;

    .product-data-warranty {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ccc;
      margin-bottom: 1rem;

      .product-warranty-data {
        text-align: center;

        .warranty-icon {
          background-color: rgba(220, 220, 220, 0.5);
          border-radius: 50%;
          width: 4rem;
          height: 4rem;
          padding: 0.6rem;
        }
        p {
          font-size: 1.4rem;
          padding-top: 0.4rem;
        }
      }
    }

    .product-data-price {
      font-weight: bold;
      font-size:20px
    }
    .product-data-real-price {
      color: ${({ theme }) => theme.colors.btn};
    }
    .product-data-info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      font-size: 1.8rem;

      span {
        font-weight: bold;
      }
    }

    hr {
      max-width: 100%;
      width: 90%;
      /* height: 0.2rem; */
      border: 0.1rem solid #000;
      color: red;
    }
  }

  .product-images {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    padding: 0 2.4rem;
  }
`;

export default SingleProduct;
