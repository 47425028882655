import { createContext, useContext, useEffect, useReducer } from "react";
import AddToCart from "../Components/AddToCart";
import reducer from '../reducer/cartReducer'

const CartContext = createContext();

const getLocalCartData = ()=>{
    let localCart = localStorage.getItem('AddToCart')

    if(localCart === []){
        return [];
    }else{
        return JSON.parse(localCart);
    }
}

const initialState = {
    // cart:[],
    cart: getLocalCartData(),
    total_item:'',
    total_amount:'',
    shipping_fee: 300,
}

const CartProvider = ({children}) =>{

    const [state, dispatch] = useReducer(reducer, initialState)

   const addToCart = (id,color,amount,product) =>{
       if(id,color,amount,product){
        dispatch({type:'ADD_TO_CART', payload: {id,color,amount,product}})
    }else{
        alert('Add Color')
        return;
    }
   }


   


//    const removeItem = (id)=>{
//     dispatch({type:'REMOVE_ITEM', payload:{id}})
//    }

const clearCart = ()=>{
    dispatch({type:'CLEAR_CART'});
};

useEffect(() => {
    dispatch({type:'CART_TOTAL_PRICE'})
    localStorage.setItem('AddToCart', JSON.stringify(state.cart))
    
}, [state.cart])


    return <CartContext.Provider value={{...state, addToCart, clearCart}}>{children}</CartContext.Provider>
}

const useCartContext = ()=>{
    return useContext(CartContext)
}

export {CartProvider, useCartContext}