// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAxxg1_6kD8wx9uwrsvCLxU5J-b5mb5Q_o",
  authDomain: "modestile-website.firebaseapp.com",
  databaseURL: "https://modestile-website-default-rtdb.firebaseio.com",
  projectId: "modestile-website",
  storageBucket: "modestile-website.appspot.com",
  messagingSenderId: "650895915496",
  appId: "1:650895915496:web:9e8735af383d404edf8c5d",
  measurementId: "G-EQK6XXJFRM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;