import React from "react";
import {BrowserRouter as Router , Routes, Route} from 'react-router-dom'
import About from "./About";
import Cart from "./Cart";
import Contact from "./Contact";
import ErrorPage from "./ErrorPage";
import Home from "./Home";
import Products from "./Products";
import SingleProduct from "./SingleProduct";
import {GlobalStyle} from './GlobalStyle'
import { ThemeProvider } from "styled-components";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Checkout from "./Checkout";
import CheckAdmin from "./Admin Screen/admin";
import ResponsiveDrawer from "./Admin Screen/dashboard";
import { Feedback } from "@mui/icons-material";
import Review from "./Review";
import LoginU from "./Auth/login";
import SignupU from "./Auth/signup";
import Perfume from "./Perfume";

const App = () => {
  const theme = {
    colors:{
      heading:'rgb(24,24,29)',
      text:'rgba(29,29,29,.8)',
      white:'#fff',
      black:'#212529',
      helper:'#8490ff',

      bg:'#f8f9fa',
      navbar:'#edc531',
      footer_bg:'#926c15',
      btn:'#edc531',
      hr:'#ffffff',
      gradient:
      'linear-gradient(0deg, rgb(132 144 255) 0%, rgb(98 189 252) 100%)',
      shadow:
      '#edc531 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;',
      shadowSupport:'#edc531 0px 1px 4px '
    },
    media:{
      mobile:'768px',
      tab:'998px',
    },
  };
  return (
  <>
  <ThemeProvider theme={theme}>
  <Router>
    <GlobalStyle />
    
    <Routes>
      <Route path='/' element={<Home />}/>
      <Route path='about' element={<About />}/>
      <Route path='caps' element={<Products />}/>
      <Route path='contact' element={<Contact />}/>
      <Route path='single/:id' element={<SingleProduct />}/>
      <Route path='cart' element={<Cart />}/>
      <Route path='perfume' element={<Perfume />}/>
      <Route path='review' element={<Review />}/>
      <Route path='cart/checkout' element={<Checkout />}/>
      <Route path='wp-admin' element={<CheckAdmin />}/>
      <Route path='login' element={<LoginU />}/>
      <Route path='signup' element={<SignupU />}/>
      <Route path='a5fgh4-0565-hgseu-4s5as-s54s54d5sh5ggs/*' element={<ResponsiveDrawer />}/>
      <Route path='*' element={<ErrorPage />}/>
    </Routes>
    
  </Router>
  </ThemeProvider>
  </>
  )
};

export default App;
