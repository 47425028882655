// import React, { useState } from 'react'
// import { NavLink } from 'react-router-dom';
// import styled from 'styled-components'
// import { FiShoppingCart } from "react-icons/fi";
// import { CgClose, CgMenu } from "react-icons/cg";
// import { Box } from '@mui/material';
// import { useCartContext } from '../context/cartContext';

// const Navbar = () => {
//   const {cart} = useCartContext();
//   const [active, setActive] = useState()
//   return (
//     <>
//     <Nav>
//         <div className={active? "navbar active": "navbar"}>
//             <Box sx={{padding: 4,}}>
//             <ul className='navbar-lists'>
//                 <li>
//                     <NavLink to='/' className='navbar-link home-link' onClick={()=>setActive(false)}>Home</NavLink>
//                 </li>
//                 <li>
//                     <NavLink to='/about' className='navbar-link ' onClick={()=>setActive(false)}>About</NavLink>
//                 </li>
//                 <li>
//                     <NavLink to='/product' className='navbar-link ' onClick={()=>setActive(false)}>Product</NavLink>
//                 </li>
//                 <li>
//                     <NavLink to='/contact' className='navbar-link ' onClick={()=>setActive(false)}>Contact</NavLink>
//                 </li>
//                 <li>
//                     <NavLink to='/cart' className='navbar-link cart-trolley--link'>
//                         <FiShoppingCart className='cart-trolley'/>
//                         <span className='cart-total--item'>{cart.length}</span>
//                         </NavLink>
//                 </li>
//             </ul>

//             {/* two button of open and close */}
//             <div className='mobile-navbar-btn'>
//                 <CgMenu name='menu-outline' onClick={()=>setActive(true)} className='mobile-nav-icon'/>
//                 <CgClose name='close-outline' onClick={()=>setActive(false)} className='mobile-nav-icon close-outline'/>
//             </div>
//             </Box>
//         </div>
//     </Nav>
//     </>
//   )
// }

// const Nav = styled.nav`
// .navbar-lists {
//   display: flex;
//   gap: 4.8rem;
//   align-items: center;
//   .navbar-link {
//     &:link,
//     &:visited {
//       display: inline-block;
//       text-decoration: none;
//       font-size: 1.8rem;
//       font-weight: 500;
//       text-transform: uppercase;
//       color: ${({ theme }) => theme.colors.black};
//       transition: color 0.3s linear;
//     }
//     &:hover,
//     &:active {
//       color: ${({ theme }) => theme.colors.helper};
//     }
//   }
// }
// .mobile-navbar-btn {
//   display: none;
//   background-color: transparent;
//   cursor: pointer;
//   border: none;
// }
// .mobile-nav-icon[name="close-outline"] {
//   display: none;
// }
// .close-outline {
//   display: none;
// }
// .cart-trolley--link {
//   position: relative;
//   .cart-trolley {
//     position: relative;
//     font-size: 3.2rem;
//   }
//   .cart-total--item {
//     width: 2.4rem;
//     height: 2.4rem;
//     position: absolute;
//     background-color: #000;
//     color: #000;
//     border-radius: 50%;
//     display: grid;
//     place-items: center;
//     top: -20%;
//     left: 70%;
//     background-color: ${({ theme }) => theme.colors.helper};
//   }
// }
// .user-login--name {
//   text-transform: capitalize;
// }
// .user-logout,
// .user-login {
//   font-size: 1.4rem;
//   padding: 0.8rem 1.4rem;
// }
// @media (max-width: ${({ theme }) => theme.media.mobile}) {
//   .mobile-navbar-btn {
//     display: inline-block;
//     z-index: 9999;
//     border: ${({ theme }) => theme.colors.black};
//     .mobile-nav-icon {
//       font-size: 4.2rem;
//       color: ${({ theme }) => theme.colors.black};
//     }
//   }
//   .active .mobile-nav-icon {
//     display: none;
//     font-size: 4.2rem;
//     position: absolute;
//     top: 30%;
//     right: 10%;
//     color: ${({ theme }) => theme.colors.black};
//     z-index: 9999;
//   }
//   .active .close-outline {
//     display: inline-block;
//   }
//   .navbar-lists {
//     width: 100vw;
//     height: 100vh;
//     position: absolute;
//     top: 0;
//     left: 0;
//     background-color: #fff;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-direction: column;
//     visibility: hidden;
//     opacity: 0;
//     transform: translateX(100%);
//     /* transform-origin: top; */
//     transition: all 0.8s linear;
//   }
//   .active .navbar-lists {
//     visibility: visible;
//     opacity: 1;
//     transform: translateX(0);
//     z-index: 999;
//     .navbar-link {
//       font-size: 4.2rem;
//     }
//   }
//   .cart-trolley--link {
//     position: relative;
//     .cart-trolley {
//       position: relative;
//       font-size: 5.2rem;
//     }
//     .cart-total--item {
//       width: 4.2rem;
//       height: 4.2rem;
//       font-size: 2rem;
//     }
//   }
//   .user-logout,
//   .user-login {
//     font-size: 2.2rem;
//     padding: 0.8rem 1.4rem;
//   }
// }
// `;

// export default Navbar


import React from 'react'
import CallIcon from '@mui/icons-material/Call';
import { Email } from '@mui/icons-material';
import './services.css'
import { NavLink } from 'react-router-dom';
import { FiShoppingCart } from "react-icons/fi";
import { useCartContext } from '../context/cartContext';
import Heart from '@mui/icons-material/Favorite';
import Person from '@mui/icons-material/Person';
import { Dropdown, NavDropdown } from 'react-bootstrap';
import { FormControl, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';

const Navbar = () => {
  const { cart } = useCartContext();
  

  return (
    <div>
     
      <div style={{ background: '#dee2e6' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <img src='images/sensionlogo.png' width='25%' alt='Logo' />
          </div>
          <div style={{ display: 'flex' }}>
            
          </div>
        </div>
      </div>
    
      </div>
      )
}

      export default Navbar
