
const ProductReducer = (state, action) => {



    switch (action.type) {
        case 'SET_LOADING':

            return {
                ...state,
                isLoading: true
            };


            case 'API_DATA':

            const featuredData = action.payload.filter((e)=>{
                return e.featured === true;
            })

            return{
                ...state,
                isLoading: false,
                products: action.payload,
                featureProducts: featuredData,
            }

        case 'API_ERROR':
            return {
                ...state,
                isLoading: false,
                isError: true
            }

            // single product

            case 'SET_SINGLE_LOADING':

            return {
                ...state,
                isLoading: true
            };

            case 'SINGLE_DATA':

            return {
                ...state,
                isLoading: false,
                singleProduct: action.payload,
            };

            case 'SET_SINGLE_ERROR':
            return {
                ...state,
                isLoading: false,
                isError: true
            }

        default:
            return state;

    }


}

export default ProductReducer
