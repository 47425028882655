import { Box, Button, Grid, TextField } from '@mui/material'
import { Container, fontSize } from '@mui/system'
import React from 'react'
import { getData, sendData } from '../firebase/firebasemethod'
import { useState } from 'react'
import { useEffect } from 'react'

const Services = () => {

  const [data, setData] = useState([])
  const [value, setValue] = useState('')
  const [dataPrice, setDataPrice] = useState([])
  const [sData, SetSData] = useState([])

  const get = () => {
    getData('Review').then((res) => {
      setData(res)
    })
  }

  // console.log(data)

  useEffect(() => {
    get()
  }, [])

  const handleChange = (e) => {
    setValue(e.target.value)
  }


  const getDataPrice = () => {
    getData('Review', value).then((res) => {
      setDataPrice(res)
    }).catch((err)=>{
      console.log(err)
    })
  }

  console.log(sData)

  const sendDataFirebase = ()=>{
    if(value){
      if(sData.name && sData.address && sData.area && sData.number){
        sendData(sData, 'Orders').then((res)=>{
          console.log(res)
          alert('Thanks For Order')
          window.location.reload();
        }).catch((err)=>{
          console.log(err)
        })
      }else{
        alert('Please fill required fields')
      }
     
    }else{
      alert('Please Select Perfume')
    }
  }


    getDataPrice()

    const discountAmount = value/100*80;

   const nonZero =  parseInt(discountAmount)

  console.log(value)
  return (
    <>
      <Box sx={{ textAlign: 'center', marginTop: 5 }}>
        <h2>Pre Order Booking Form </h2>
      </Box>
      <Box sx={{ flexGrow: 1, marginTop: 5 }}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <Container>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} xs={12} sm={12}>

                    <Box sx={{ flexGrow: 1, marginTop: 5 }}>
                      <Grid container spacing={2}>

                      <Grid item xs={12} lg={12} sm={12} md={12} >
                          <div style={{ marginBottom: 30 }}>
                            <div  >
                            <h3 style={{marginBottom:20}}><b>Select Perfume</b><span style={{color:'red'}}> *</span></h3>
                              <select onChange={handleChange} style={{ width: '100%', padding: 10 }}>
                                {data?.map((e, i) => {
                                  return (
                                    
                                    <option key={i} value={e.price}>{e.name} | Price:{e.price}</option>
                                    
                                  )

                                })}
                              </select>
                            </div>

                          </div>
                        </Grid>

                        <Grid item xs={12} lg={6} sm={12} md={6} >
                          <h3><b>First Name</b><span style={{color:'red'}}> *</span></h3>
                          <input className='input' style={{ width: '100%', fontSize: '20px' }} onChange={e => SetSData({ ...sData, name: e.target.value, perfume: value })} required variant="standard" />
                        </Grid>
                        <Grid item xs={12} lg={6} sm={12} md={6} >
                          <h3><b>Last Name</b></h3>
                          <input className='input' style={{ width: '100%', fontSize: '20px' }} onChange={e => SetSData({ ...sData, lastname: e.target.value })} variant="standard" />
                        </Grid>
                        <Grid item xs={12} lg={6} sm={12} md={6} >
                          <h3><b>Street Address</b><span style={{color:'red'}}> *</span></h3>
                          <input className='input' style={{ width: '100%', fontSize: '20px' }} onChange={e => SetSData({ ...sData, address: e.target.value })} required variant="standard" />
                        </Grid>
                        <Grid item xs={12} lg={6} sm={12} md={6} >
                          <h3><b>Area</b><span style={{color:'red'}}> *</span></h3>
                          <input className='input' style={{ width: '100%', fontSize: '20px' }} onChange={e => SetSData({ ...sData, area: e.target.value })} required variant="standard" />
                        </Grid>
                        <Grid item xs={12} lg={6} sm={12} md={6} >
                          <h3><b>City</b><span style={{color:'red'}}> *</span></h3>
                          <input className='input' style={{ width: '100%', fontSize: '20px' }} onChange={e => SetSData({ ...sData, city: e.target.value })} required variant="standard" />
                        </Grid>
                        <Grid item xs={12} lg={6} sm={12} md={6} >
                          <h3><b>State/Province</b></h3>
                          <input className='input' style={{ width: '100%', fontSize: '20px' }} onChange={e => SetSData({ ...sData, province: e.target.value })}  variant="standard" />
                        </Grid>
                        <Grid item xs={12} lg={6} sm={12} md={6} >
                          <h3><b>Country</b></h3>
                          <input className='input' style={{ width: '100%', fontSize: '20px' }} onChange={e => SetSData({ ...sData, country: e.target.value })} requred variant="standard" />
                        </Grid>
                        <Grid item xs={12} lg={6} sm={12} md={6} >
                          <h3><b>Zip/Postal Code</b></h3>
                          <input className='input' style={{ width: '100%', fontSize: '20px' }} onChange={e => SetSData({ ...sData, zip: e.target.value })}  variant="standard" />
                        </Grid>
                        <Grid item xs={12} lg={6} sm={12} md={6} >
                          <h3><b>Phone Number</b><span style={{color:'red'}}> *</span></h3>
                          <input className='input' style={{ width: '100%', fontSize: '20px' }} onChange={e => SetSData({ ...sData, number: e.target.value })} required variant="standard" />
                        </Grid>
                        <Grid item xs={12} lg={6} sm={12} md={6} >
                          <h3><b>Email Address</b></h3>
                          <input className='input' style={{ width: '100%', fontSize: '20px' }} onChange={e => SetSData({ ...sData, email: e.target.value })} variant="standard" />
                        </Grid>
                      
                        <Grid item xs={12} lg={6} sm={12} md={6} >
                        </Grid>
                                
                        <Grid item xs={12} lg={6} sm={12} md={6} >
                        {/* <div>
                          <div >
                            <div>
                              <p>Subtotal:</p>
                              <p>679</p>
                            </div>
                            <div>
                              <p>Shipping Fee</p>
                              <p>979</p>
                            </div>
                            <hr />
                            <div>
                              <p>Total Amount</p>
                              <p>790</p>
                            </div>

                          </div>
                          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                            <div >
                              <Button>Check Out</Button>
                            </div>
                          </div>
                        </div> */}

                        =
                            <div style={{marginTop:20, backgroundColor:'#dee2e6', padding: 20, borderRadius:15}}>
                          <div >
                            <div style={{fontSize:15, display:'flex', justifyContent:'space-between', padding:10}}>
                            <p>Subtotal:</p>
                            <p>{value}</p>
                            </div>
                            <div style={{fontSize:15, display:'flex', justifyContent:'space-between', padding:10}}>
                            <p>Discount:</p>
                            <p>20%</p>
                            </div>
                            <hr />
                            <div style={{fontSize:15, display:'flex', justifyContent:'space-between', padding:10}}>
                            <p>Total Amount:</p>
                            <p>{nonZero}</p>
                            </div>
                           

                          </div>
                        </div>
                        <div>
                        <div style={{float:'right', marginTop:40}}>
                              <button style={{padding:10, backgroundColor:'black', cursor:'pointer', borderRadius:10,color:'white', fontSize:18 }} onClick={sendDataFirebase}>Checkout</button>
                            </div>
                          </div>

                        
                        </Grid>
                        
                      </Grid>
                    </Box>
                  </Grid>

                </Grid>
              </Box>

            </Container>
          </Grid>

        </Grid>
      </Box>
    </>
  )
}

export default Services
