import { Button, CircularProgress, Grid, Paper, Table, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { ref, uploadBytes } from 'firebase/storage'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { storage } from '../../firebase/firebaseconfig'
import { deleteItem, Edit, getData, sendData } from '../../firebase/firebasemethod';
import { uploadImage } from '../../firebase/firebaseMethod1';
import { v4 } from 'uuid'
import { Database } from 'firebase/database'
import { useNavigate, useNavigation } from 'react-router-dom'
import './style.css'

const AddProductHome = () => {
    const [product, setProduct] = useState([])
    const [getProduct, getsetProduct] = useState([])
    console.log(getProduct);
    // const [imageUpload, setImageupload] = useState(null)
    let [image, setImage] = useState("");
    const naviagate = useNavigate()
    // console.log(product)
    // console.log(getProduct)

    // const send = () => {
    //     sendData(product, 'Products').then((res) => {
    //         alert('Add Product Successfully')
    //         window.location.reload()
    //     })
    // }
    const get = () => {
        getData('Products').then((res) => {
            getsetProduct(res)
            // setProduct('')
        })
    }

    const delete123 = (e)=>{
        console.log(e.key)
        deleteItem('Products',e.key).then((res)=>{
            alert(res)
            window.location.reload()
        }).catch((err)=>{
            alert(err)
        })
    }

    
    
    useEffect(() => {
        get()
    }, [])
    
    const uploadImage123 = () => {
        console.log(image);
        uploadImage(image, "Images", product, "Products").then(() => {
            alert("Successfully upload")
            window.location.reload()
        }).catch(() => {
            alert("sOMETHING IS WRONG");
        })
    }

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField fullWidth label='Product ID' variant='standard' onChange={e => setProduct({ ...product, pId: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField fullWidth label='Product Name' variant='standard' onChange={e => setProduct({ ...product, name: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField fullWidth label='Product Price' variant='standard' onChange={e => setProduct({ ...product, price: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField fullWidth label='Product Special Price' variant='standard' onChange={e => setProduct({ ...product, sPrice: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField fullWidth label='Product Description' variant='standard' onChange={e => setProduct({ ...product, desc: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        {/* <TextField fullWidth label='Product Image src' variant='standard' onChange={e=>setProduct({...product,image:e.target.value})}/> */}
                        <label>Select Image</label>
                        <input type="file" onChange={(e) => setImage(e.target.files[0])} />
                        {/* <Button onClick={uploadImage}>Upload Image</Button> */}
                    </Grid>
                </Grid>
            </Box>
            <Button onClick={uploadImage123}>Add Product</Button>

            <div>
                <Box sx={{ mt: 5 }}>

                    <h3 className='regisdetail'>All Products Details</h3>


                    <Table responsive border={1} hover sx={{ mr: 20 }} component={Paper}>
                        <thead>
                            <tr>
                                <th style={{ paddingLeft: 30, paddingRight: 30 }}>#</th>
                                <th style={{ paddingLeft: 30, paddingRight: 30 }}>Name</th>
                                <th style={{ paddingLeft: 30, paddingRight: 30 }}>Price</th>
                                <th style={{ paddingLeft: 30, paddingRight: 30 }}> Special Price </th>
                                <th style={{ paddingLeft: 30, paddingRight: 30 }}> Description </th>
                                <th style={{ paddingLeft: 30, paddingRight: 30 }}> Edit </th>
                                <th style={{ paddingLeft: 30, paddingRight: 30 }}> Delete </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                getProduct.map((e, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{e.name}</td>
                                            <td>{e.price}</td>
                                            <td>{e.sPrice}</td>
                                            <td>{e.desc}</td>
                                            <Button sx={{ color: 'black' }} onClick={() => naviagate("EditProduct", {state: e})}>Edit</Button>
                                            <Button  sx={{ color: 'black' }} onClick={()=>delete123(e)}>Delete</Button>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>

                </Box>
            </div>
        </>

    )
}

export default AddProductHome
