import { CircularProgress, Grid, TextField } from '@mui/material';
import { Box, Container } from '@mui/system';
import React, { useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Footer from './Components/Footer';
import Header from './Components/Header';
import { sendData } from './firebase/firebasemethod';
import { Button } from './styles/Button';
import ReactStars from 'react-rating-stars-component'

const ReviewPage = () => {
    const [model, setModel] = useState('')
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    console.log(model.rating)

    const submit = ()=>{

        if (model.rating > 5) {
            alert('Maximum Rating 5')
        }else{
            setLoader(true)
            if (Object.values(model).length >= 4) {
                sendData(model, 'Review')
                .then((success) => {
                    // Signed in 
                    setLoader(false)
                    setModel('')
                    
                })
                .catch((error) => {
                        setLoader(false)
                        const errorMessage = error.message;
                        const errorMessageUp = errorMessage.toUpperCase();
                        alert(errorMessageUp)
                    });
                    
            } else {
                alert('ALL FIELDS MUST BE FILLED')
            }
        }
    }

  return (
      
      <div style={{textAlign:'center'}}>
          <Header />
        <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} sm={0} xs={0}>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
            <Container>
        <h1>Review</h1>
      
      <>
      <FloatingLabel
        controlId="floatingInput"
        className="mb-3"
      >
      <ReactStars size={50} isHalf={true} onChange={rating=>setModel({...model,star:rating})} classNames='star'/>
        <Form.Control type="text" onChange={e=>setModel({...model,name:e.target.value})} placeholder="Name" style={{width:'70%'}}/>
      </FloatingLabel>
      <FloatingLabel controlId="floatingPassword" >
        <Form.Control type="email" onChange={e=>setModel({...model,email:e.target.value})} style={{width:'70%', marginTop:20}} placeholder="Email" />
      </FloatingLabel>
    </>
        <br />
      <FloatingLabel controlId="floatingTextarea2" >
        <Form.Control
          as="textarea"
          placeholder="Leave a comment here"
          style={{ height: '100px',width:'70%', marginTop:15 }}
          onChange={e=>setModel({...model,reviewComment:e.target.value})}
        />
      </FloatingLabel>
        
        <br />
        <Button onClick={submit}>{loader? <CircularProgress />: 'Submit Review'}</Button>
      
      </Container>
        </Grid>
        <Grid item lg={4} md={4} sm={0} xs={0}>
        </Grid>
      </Grid>
    </Box>
      
     <Footer />
    </div>
  );
};

export default ReviewPage;
