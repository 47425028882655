import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {  deleteItem, getData } from '../../firebase/firebasemethod';
import { Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import './style.css'

export default function StdDetails() {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getData('Review')
            .then((res) => {
                setData(Object.values(res))
                // console.log(res);
                setIsLoading(false)
            })
            .catch((err) => {
                alert(err);
                setIsLoading(false)
            })
    }, []);
    let column = [
        {heading :'name'},
        {heading :'email'},
        {heading :'phone'},
      ]
  
  
      const delete123 = (e)=>{
        console.log(e.id)
        deleteItem('Review',e.id).then((res)=>{
            window.location.reload()
            alert(res)
        }).catch((err)=>{
            alert(err)
        })
    }


    
    // console.log(data)

    return (
    
        <div>
            <Box sx={{mt:5}}>
            
            <h3 className='regisdetail'>All Review Details</h3>
            {
                isLoading ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                        <CircularProgress />
                    </Box>
                    :

                    <Table responsive hover sx={{mr:20}} component={Paper}>
                        <thead>
                            <tr>
                                <th style={{paddingLeft:30,paddingRight:30}}>#</th>
                                <th style={{paddingLeft:30,paddingRight:30}}>Namw</th>
                                <th style={{paddingLeft:30,paddingRight:30}}>Email</th>
                                <th style={{paddingLeft:30,paddingRight:30}}> Message </th>
                                <th style={{paddingLeft:30,paddingRight:30}}> Rating </th>
                                <th style={{paddingLeft:30,paddingRight:30}}> Delete </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((e, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{e.name}</td>
                                            <td>{e.email}</td>
                                            <td>{e.reviewComment}</td>
                                            <td>{e.star}</td>
                                            <Button  sx={{backgroundColor:'red',color:'black'}} onClick={()=>delete123(e)}>Delete</Button>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
            }
            </Box>
        </div>
    );
}