import React, { useState } from 'react'
import { FaMinus, FaPlus, FaTrash } from 'react-icons/fa'
import { useCartContext } from '../context/cartContext'
import CardToggleAmount from './CardToggleAmount'


const CartItem = ({id, name, image, color, price, amount}) => {
  const {cart } = useCartContext();

  // const [list, setList] = useState([cart])
  // const [amounts, setAmount] = useState(amount)

  const del = (id) => {
    cart.splice(id,1);
    console.log(id)
  }

  // const setIncreament = (id)=>{
  //   amount + 1
  // }
  // const setDecreament = (id)=>{
  //   amount + 1
  // }

  return (
    <>
    <a href=""></a>
      <div className="cart-heading grid grid-five-column">
        <div className="cart-image--name">
          <div>
            <figure>
              <img src={image} alt={id} />
            </figure>
          </div>
          <div>
          <p>{name}</p>
        <div className="color-div">
          <p>color:</p>
          <div className='color-style' style={{backgroundColor:color}}></div>
        </div>
          </div>
        </div>
        
        <div className='cart-hide'>
          <p>{price}</p>
        </div>

        <div>
        <>
    <div className='cart-button'>
        <div className='amount-toggle'>
           
            <div className='amount-style'>{amount}</div>
            
        </div>
    </div>
    </>
        </div>

        <div className="cart-hide">
          <p>{price * amount}</p>
        </div>

        <div onClick={()=>del(id)}>
          <FaTrash className='remove_icon' />
        </div>
      </div>
    </>
  )
}

export default CartItem
