// // import React from 'react'
// // import { NavLink } from 'react-router-dom';
// // import styled from "styled-components";
// // import { Button } from '../styles/Button';
// // import Box from '@mui/material/Box';
// // import Grid from '@mui/material/Grid';
// // import { Container } from '@mui/system';


// // const HeroSection = (props) => {
// //   const {name} = props
// //   return (
// //     <>
// //       <Wrapper>
// //       <Box sx={{ flexGrow: 1 }}>
// //         <Container>
// //       <Grid container spacing={2}>
// //         <Grid item xs={12} lg={6} md={6} sm={12}>
// //           <Box className='hero-section-data'>
// //           <p>Welcome to</p>
// //           <h1>{name}</h1>
// //           <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.
// //              Quo quos exercitationem odit est, vitae eligendi beatae! Labore,
// //              fugiat error. Fugit laborum minima
// //               tempore perspiciatis est sequi cum nesciunt, explicabo corporis?</p>
// //           </Box>
// //           <NavLink>

// //           <Button>Shop Now</Button>
// //           </NavLink>
// //         </Grid>
// //         <Grid item xs={12} lg={6} md={6} sm={12}>
// //           <figure className='hero-section-image'>
// //           <img src="images/hero.jpg" alt=""  className='img-style'/>
// //           </figure>
// //         </Grid>
// //       </Grid>
// //       </Container>
// //     </Box>
// //       </Wrapper>
// //     </>
// //   )
// // }

// // const Wrapper = styled.section`
// //   padding: 12rem 0;
// //   img {
// //     min-width: 10rem;
// //     height: 10rem;
// //   }
// //   .hero-section-data {
// //     p {
// //       margin: 2rem 0;
// //     }
// //     h1 {
// //       text-transform: capitalize;
// //       font-weight: bold;
// //     }
// //     .intro-data {
// //       margin-bottom: 0;
// //     }
// //   }
// //   .hero-section-image {
// //     width: 100%;
// //     height: auto;
// //     display: flex;
// //     justify-content: center;
// //     align-items: center;
// //   }
// //   figure {
// //     position: relative;
// //     &::after {
// //       content: "";
// //       width: 60%;
// //       height: 80%;
// //       background-color: rgba(81, 56, 238, 0.4);
// //       position: absolute;
// //       left: 50%;
// //       top: -5rem;
// //       z-index: -1;
// //     }
// //   }
// //   .img-style {
// //     width: 100%;
// //     height: auto;
// //   }
// //   @media (max-width: ${({ theme }) => theme.media.mobile}) {
// //     .grid {
// //       gap: 10rem;
// //     }
// //     figure::after {
// //       content: "";
// //       width: 50%;
// //       height: 100%;
// //       left: 0;
// //       top: 10%;
// //       /* bottom: 10%; */
// //       background-color: rgba(81, 56, 238, 0.4);
// //     }
// //   }

// // `;

// // export default HeroSection

// import React, { useState, useEffect } from 'react';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// function ImageSlider() {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [slides, setSlides] = useState([
//     { image: 'images/slider 1.png', caption: 'Caption for image 1' },
//     { image: 'images/slider 2.jpeg', caption: 'Caption for image 2' },
//   ]);

//   useEffect(() => {
//     const intervalId = setInterval(nextSlide, 5000);
//     return () => clearInterval(intervalId);
//   }, []);

//   function nextSlide() {
//     const nextIndex = currentIndex + 1;
//     if (nextIndex >= slides.length) {
//       setCurrentIndex(0);
//     } else {
//       setCurrentIndex(nextIndex);
//     }
//   }

//   function prevSlide() {
//     const prevIndex = currentIndex - 1;
//     if (prevIndex < 0) {
//       setCurrentIndex(slides.length - 1);
//     } else {
//       setCurrentIndex(prevIndex);
//     }
//   }

//   return (
//     <div className="image-slider">
//       <img src={slides[currentIndex].image} alt="Slide" width='100%' height='900px'/>
//       <div style={{position:'absolute', top:500,cursor:'pointer'}}>
//       <h1 onClick={prevSlide}><ArrowBackIosNewIcon fontSize='60px' color='white'/></h1>
//       </div>
//       <div style={{position:'absolute', top:500, right:10, cursor:'pointer'}}>
//       <h1 onClick={nextSlide}><ArrowForwardIosIcon fontSize='60px' /></h1>
//       </div>
//     </div>
//   );
// }

// export default ImageSlider;



// import React, { Component } from 'react';
// // import ReactDOM from 'react-dom';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';

// const ImageSlider =()=> {
    
//         return (
//             <Carousel>
//                 <div>
//                     <img src="images/slider 1.png" width='100%'/>
//                 </div>
//                 <div>
//                     <img src="images/slider 2.jpeg" />
//                 </div>
                
//             </Carousel>
//         );
    
// };

// ReactDOM.render(<DemoCarousel />, document.querySelector('.demo-carousel'));

// export default ImageSlider

// Don't forget to include the css in your page

// Using webpack or parcel with a style loader
// import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

// Using html tag:
// <link rel="stylesheet" href="<NODE_MODULES_FOLDER>/react-responsive-carousel/lib/styles/carousel.min.css"/>


// import { Carousel } from 'react-carousel-minimal';
import { Carousel } from 'react-responsive-carousel';


function ImageSlider() {



         return (
            <div>
                <video width="100%" height='500px' controls="controls">
  <source src="images/sensionvideo.mp4" type="video/mp4" />
</video>
              
                
            </div>
        );
}

export default ImageSlider;