import { Box, Grid, TextField } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import { useLocation } from 'react-router-dom'

const Checkout = () => {
  const location = useLocation()

  console.log(location.state)
  return (
    <>
    <Box sx={{textAlign:'center', marginTop:5}}>
    <h2>CHECKOUT</h2>
    </Box>
      <Box sx={{ flexGrow: 1, marginTop: 5 }}>
        <Grid container spacing={2}>
          <Grid item lg={8} md={8} xs={12} sm={12}>
            <Container>
            <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} xs={12} sm={12}>
        <h2>Shipping Address</h2>
              <Box sx={{ flexGrow: 1, marginTop:5 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12} sm={12} md={12} >
                    <h3><b>Email Address</b><span> *</span></h3>
                    <TextField  color="success" required sx={{fontSize: "20px"}} style={{fontSize: "50px"}} variant="standard" />
                    {/* <input type="text" style={{fontSize: "20px"}} /> */}
                  </Grid> 
                  
                  <Grid item xs={12} lg={6} sm={12} md={6} >
                    <h3><b>First Name</b><span> *</span></h3>
                    <TextField  color="success" fullWidth  className='input'  required variant="standard" />
                  </Grid>
                  <Grid item xs={12} lg={6} sm={12} md={6} >
                    <h3><b>Last Name</b><span> *</span></h3>
                    <TextField  color="success" fullWidth  className='input'  required variant="standard" />
                  </Grid>
                  <Grid item xs={12} lg={12} sm={12} md={12} >
                    <h3><b>Street Address</b><span> *</span></h3>
                    <TextField  color="success" fullWidth  className='input'  required variant="standard" />
                  </Grid>
                  <Grid item xs={12} lg={6} sm={12} md={6} >
                    <h3><b>Country</b><span> *</span></h3>
                    <TextField  color="success" fullWidth  className='input'  required variant="standard" />
                  </Grid>
                  <Grid item xs={12} lg={6} sm={12} md={6} >
                    <h3><b>State/Province</b><span> *</span></h3>
                    <TextField  color="success" fullWidth  className='input'  required variant="standard" />
                  </Grid>
                  <Grid item xs={12} lg={6} sm={12} md={6} >
                    <h3><b>City</b><span> *</span></h3>
                    <TextField  color="success" fullWidth  className='input'  required variant="standard" />
                  </Grid>
                  <Grid item xs={12} lg={6} sm={12} md={6} >
                    <h3><b>Zip/Postal Code</b><span> *</span></h3>
                    <TextField  color="success" fullWidth  className='input'  required variant="standard" />
                  </Grid>
                  <Grid item xs={12} lg={6} sm={12} md={6} >
                    <h3><b>Phone Number</b><span> *</span></h3>
                    <TextField  color="success" fullWidth  className='input'  required variant="standard" />
                  </Grid>
                 
                </Grid>
              </Box>
        </Grid>
        <Grid item lg={6} md={6} xs={0} sm={0}>
        </Grid>
      </Grid>
    </Box>
             
            </Container>
          </Grid>
          <Grid item lg={4} md={4} xs={12} sm={12}>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Checkout
