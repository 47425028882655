import React from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa'

const CardToggleAmount = ({amount, setIncreament, setDecreament}) => {
  return (
    <>
    <div className=''>
        <div className='amount-toggle'>
            <button onClick={()=>setDecreament()}>
                <FaMinus />
            </button>
            <div className='amount-style'>{amount}</div>
            <button onClick={()=>setIncreament()}>
                <FaPlus />
            </button>
        </div>
    </div>
    </>
  )
}

export default CardToggleAmount
