import React from 'react'
import Footer from './Components/Footer'
import Navbar from './Components/Navbar'

const Perfume = () => {
  return (
    <>
    <Navbar />

<div style={{marginTop:10}}>

    <h2 style={{textAlign:'center', marginTop:30}}>Attar & Perfume</h2>
    <h1 style={{textAlign:'center', marginTop:30}}>Coming Soon</h1>

</div>
    <Footer />
      
    </>
  )
}

export default Perfume
