// create context 
// provider 
// consumer 

import axios from "axios";
import { useState } from "react";
import { createContext, useContext, useEffect, useReducer } from "react";
import { getData } from "../firebase/firebasemethod";
import reducer from '../reducer/productReducer'

const API = 'https://api.pujakaitem.com/api/products'





const initialState = {
    isLoading: false,
    isError: false,
    products: [],
    featureProducts: [],
    isSingleLoading: false,
    isSingleError:false,
    singleProduct:{},
}


const AppContext = createContext();




const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)


    const getProducts = async (url) => {
        dispatch({type:'SET_LOADING'})

        try {
            
            const res = await axios.get(url);
            const products = await res.data
            dispatch({type: 'API_DATA', payload: products})
        } catch (error) {
            dispatch({type:'API_ERROR'})
        }
    }

    const getSingleProduct = async (url)=>{
        dispatch({type:'SET_SINGLE_LOADING'})

        try {
            const res = await axios.get(url);
            const singleProduct = await res.data 
            dispatch({type: 'SINGLE_DATA', payload: singleProduct})
        } catch (error) {
            dispatch({type:'SET_SINGLE_ERROR'})
        }
    }

    useEffect(() => {
        getProducts(API)

    }, [])



    return (
        <AppContext.Provider value={{ ...state, getSingleProduct }}>{children}</AppContext.Provider>
    )
}

// custom hook 

const useProductContext = () => {
    return useContext(AppContext)
}


export { AppContext, AppProvider, useProductContext }