import React, { useState } from "react";
import styled from "styled-components";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import ImageSlider from "./Components/HeroSection";
import HeroSection from "./Components/HeroSection";
import MainData from "./Components/MainData";
import MainProduct from "./Components/mainProduct";
import Services from "./Components/Services";
import { getData } from "./firebase/firebasemethod";
import Slider from "react-slick";
import ReviewSlider from "./Components/ReviewSlider";
import FullScreenLoader from "./Components/fullScreenLoader";

const Home = () => {

  const [Data, setData] = useState([])
  const [loader, setLoader] = useState(true)
  setTimeout(() => {
    setLoader(false)
  }, 4000);
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500
  };

  const data = ()=>{
    getData('Review').then((res)=>{
      setData(Object.values(res))
    }).catch((err)=>{
      console.log(err)
    })
  }

  data()

  return (
   <>
    <>
    <FullScreenLoader />
          
          </>
      <>
      <Header />
      <ImageSlider name={'Website'}/>
    <Services />
    
    {/* <MainData /> */}
     
     {/* <ReviewSlider /> */}

    <Footer />
    </>
    
    
    </>
    )
};

const Wrapper = styled.section`
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.bg};
`;



export default Home;