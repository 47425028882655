import Grid from '@mui/material/Grid';
import { Box, Container } from '@mui/system'
import React from 'react'
import { Button } from '../styles/Button'
import './footer.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FaTiktok } from 'react-icons/fa';

const Footer = () => {
  return (
    <>
    <Wrapper>
   
        
        <div  className='footer-bottom--section'>
      
      <div style={{display:'flex', justifyContent:'space-between', backgroundColor:'#dee2e6', padding:20}}>
        <div style={{display:'flex'}}>
      <div style={{marginLeft:20}}>
        <h2 style={{color:'black'}}>©️ {new Date().getFullYear()} Sension</h2>
      </div>
      <div style={{marginLeft:20}}>
        <h2 style={{color:'black'}}> All Rights Reserved </h2>
      </div>
      </div>

      <div style={{display:'flex'}}>
        <div  style={{padding:10}} >
        <a href="https://www.tiktok.com/@sensionpk"> <FaTiktok style={{fontSize:31, color:'black' } }/></a>
           
           </div>
        <div style={{padding:10}}>
          <a href='https://www.instagram.com/sensionpk/'><InstagramIcon  style={{fontSize:33, color: 'purple'}}/></a>
          
          </div>
        <div style={{padding:10}}>
          <a href='https://www.facebook.com/sensionpk/'>
          <FacebookIcon  style={{fontSize:35, color: 'blue'}}/>
          </a>
          </div>
        <div></div>
        <div></div>
      </div>
    </div>
      </div>
  
      </Wrapper>
    </>
  )
}

const Wrapper = styled.section`
  .iSIFGq {
    margin: 0;
  }
  .contact-short {
    max-width: 60vw;
    margin: auto;
    padding: 5rem 10rem;
    background-color: ${({ theme }) => theme.colors.bg};
    border-radius: 1rem;
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
    transform: translateY(50%);
    .grid div:last-child {
      justify-self: end;
      align-self: center;
    }
  }
  .footer {
    padding: 14rem 0 2rem 0;
    background-color: ${({ theme }) => theme.colors.footer_bg};
    h3 ,p{
      color: ${({ theme }) => theme.colors.black};
      margin-bottom: 2.4rem;
      // font-weight: bold;
    }
    p:hover{
      color: white !important;
    }
    a {
      color: ${({ theme }) => theme.colors.black};
      font-weight: bold;
    }
    .footer-social--icons {
      display: flex;
      gap: 2rem;
      div {
        padding: 1rem;
        border-radius: 50%;
        border: 2px solid black;
        .icons {
          color: black;
          font-size: 2.4rem;
          position: relative;
          cursor: pointer;
        }
        .icon:hover{
          color:white !important;
        }
      }
    }
  }
  .footer-bottom--section {
    padding-top: 9rem;
    hr {
      margin-bottom: 2rem;
      color: black;
      height: 0.1px;
    }
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .contact-short {
      max-width: 80vw;
      margin: 4.8rem auto;
      transform: translateY(0%);
      text-align: center;
      .grid div:last-child {
        justify-self: center;
      }
    }
    .footer {
      padding: 9rem 0 9rem 0;
    }
    .footer-bottom--section {
      padding-top: 4.8rem;
    }
  }
`;


export default Footer
