import React from 'react'

const CartReducer = (state, action) => {

    if (action.type === 'ADD_TO_CART') {
        let { id, color, amount, product } = action.payload;
        // console.log(product)

        let existingProduct = state.cart.find((e) => e.id == id + color)

        if (existingProduct) {

            let updatedAmount = state.cart.map((x) => {
                if (x.id == id + color) {
                    const newAmount = x.amount + amount;

                    // if (newAmount >= x.max) {
                    //     newAmount = x.max
                    // }
                    return {
                        ...x,
                        amount: newAmount
                    }
                } else {
                    return x;
                }

            })
            return {
                ...state,
                cart: updatedAmount,
            }
        } else {

            let cartProduct;

            cartProduct = {
                id: id + color,
                name: product.name,
                color,
                amount,
                image: product.image,
                price: product.price,
                // stock: product.stock
            }

            if(color){
                return {
                    ...state,
                    cart: [...state.cart, cartProduct],
                }
            }else{
                alert('return') 
                
                
            }

            
        }
    }

    


    if (action.type === 'CLEAR_CART') {
        return {
            ...state,
            cart: [],
        }
    }

    // if (action.type === "CART_TOTAL_PRICE") {
    //     let { total_item, total_amount } = state.cart.reduce(
    //       (accum, curElem) => {
    //         let { price, amount } = curElem;
    
    //         accum.total_item += amount;
    //         accum.total_amount += price * amount;
    
    //         return accum;
    //       },
    //       {
    //         total_item: 0,
    //         total_amount: 0,
    //       }
    //     );
    //     return {
    //       ...state,
    //       total_item,
    //       total_amount,
    //     };
    //   }

      
    

    return state;
}

export default CartReducer
