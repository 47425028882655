const list = [
    {
      id: 1,
      Name: "My First Book of Pencil Control",
      author: "by Wonder House Books | 25 April 2018",
      stock: 10,
      price: 89,
      amount: '',
      name:'true',
      img:'https://m.media-amazon.com/images/I/81Gbz0XnW7L._AC_UY327_FMwebp_QL65_.jpg',
      images:[
        {
          url:'https://m.media-amazon.com/images/I/81Gbz0XnW7L._AC_UY327_FMwebp_QL65_.jpg'
        },
        {
          url:'https://m.media-amazon.com/images/I/81Gbz0XnW7L._AC_UY327_FMwebp_QL65_.jpg'
        },
        {
          url:'https://m.media-amazon.com/images/I/71O-FI7QApL._AC_UY327_FMwebp_QL65_.jpg'
        },
        {
          url:'https://m.media-amazon.com/images/I/71rmxx8P2qL._AC_UY327_FMwebp_QL65_.jpg'
        }
      ],
      color:[
        '#ff930f',
        '#c70505',
        '#522882'
      ],
      category: 'mobile'
    },
    {
      id: 2,
      Name: "108 Panchatantra Stories",
      author: "by Maple Press  | 1 September 2020",
      price: 98,
      img: "https://m.media-amazon.com/images/I/71rmxx8P2qL._AC_UY327_FMwebp_QL65_.jpg",
      name:'true',
      amount: '',
    },
    {
      id: 3,
      Name: "Amazing Questions & Answers Science",
      author: "by Om Books Editorial Team  | 25 November 2018",
      img: "https://m.media-amazon.com/images/I/81Gbz0XnW7L._AC_UY327_FMwebp_QL65_.jpg",
      price: 143,
      name:'true',
      amount: '',
      category: 'laptop',
    },
    {
      id: 4,
      Name: "My First Book of Pencil Control",
      author: "by Wonder House Books | 25 April 2018",
      price: 57,
      img: "https://m.media-amazon.com/images/I/81Gbz0XnW7L._AC_UY327_FMwebp_QL65_.jpg",
      amount: '',
    },
    {
      id: 5,
      Name: "My First 1000 Words",
      author: "by Wonder House Books  | 1 January 2018",
      price: 149,
      img: "https://m.media-amazon.com/images/I/71O-FI7QApL._AC_UY327_FMwebp_QL65_.jpg",
      amount: '',
    },
    {
      id: 6,
      Name: "101 Panchatantra Stories for Children",
      author: "by Om Books Editorial Team | 30 September 2020",
      price: 135,
      img: "https://m.media-amazon.com/images/I/9173YBkMIsL._AC_UY327_FMwebp_QL65_.jpg",
      amount: '',
    },
    {
      id: 7,
      Name: "Pre-School Activities Pack ",
      author: "by Om Books Editorial Team  | 1 January 2021",
      price: 693,
      img: "https://m.media-amazon.com/images/I/913sv4sex3L._AC_UY327_FMwebp_QL65_.jpg",
      amount: '',
    },
    {
      id: 8,
      Name: "Early Learning Library Pack 1",
      author: "by Wonder House Books  | 6 December 2020",
      price: 289,
      img: "https://m.media-amazon.com/images/I/71xMttNhr7L._AC_UY327_FMwebp_QL65_.jpg",
      amount: '',
    },
    {
      id: 9,
      Name: "Blossom Moral Story Book for Kids ",
      author: "by Content Team at Target Publications",
      price: 80,
      img: "https://m.media-amazon.com/images/I/7122h3jWvEL._AC_UY327_FMwebp_QL65_.jpg",
      amount: '',
    },
    {
      id: 10,
      Name: "Brain Activity Book for Kids",
      author: "by Maple Press | 1 September 2021",
      price: 86,
      img: "https://m.media-amazon.com/images/I/7175YpTSa7L._AC_UY327_FMwebp_QL65_.jpg",
      amount: '',
    },
    {
      id: 11,
      Name: "Willy the Silly Panda",
      author: "by Rebecca Smith  | 14 December 2021",
      price: 120,
      img: "https://m.media-amazon.com/images/I/71-ocPGQIJL._AC_UY327_FMwebp_QL65_.jpg",
      amount: '',
    },
    {
      id: 12,
      Name: "Grandma's Bag of Stories",
      author: "by Sudha Murty | 1 January 2015",
      price: 157,
      img: "https://m.media-amazon.com/images/I/81jv44QdNwL._AC_UY327_FMwebp_QL65_.jpg",
      amount: '',
    },
  ];
  
  export default list;