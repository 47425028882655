import React, { useState } from "react";
import {  CircularProgress, Divider, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import './main.css'
import { SignupUser } from "../firebase/firebasemethod";
import { Button } from "../styles/Button";

const SignupU = () => {
const [username, setUsername] = useState()
const [email, setEmail] = useState()
const [password, setPassword] = useState()
  let navigate = useNavigate();
  const[isLoading, setLoading] = useState(false)

  let Signupin = () => {
    setLoading(true)
    SignupUser({
      username,
      email,
      password,
      category:'user'
    }).then((res)=>{
        console.log(res)
        setLoading(false)
        navigate('/login')
    }).catch((err)=>{
        console.log(err)
        setLoading(false)
    })
}
  
  let clickEv = () => {
    navigate("/login");
  };

  return (
    <>
    <div className="container">
      <div className="main">
        <Box sx={{ mb: 2 }}>
          <img src='images/logo.png' alt="TodoLogo" width="50%" />
        </Box>
        <h3>SignUp to your account</h3>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ pb: 2 }}>
              <TextField
                label="User Name"
                onChange={(e) => setUsername(e.target.value)}
                type="email"
                variant="standard"
                sx={{ width: "90%" }}
              />
            </Box>
            <Box sx={{ pb: 2 }}>
              <TextField
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                variant="standard"
                sx={{ width: "90%" }}
              />
            </Box>
            <Box sx={{ pb: 2 }}>
              <TextField
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                variant="standard"
                size="70"
                sx={{ width: "90%" }}
              />
            </Box>
          </Box>
          <Box sx={{ p: 2 }}>
            <Button style={{width:'90%'}} className="button" variant="contained" onClick={Signupin} fullWidth>
            {isLoading? <CircularProgress color="inherit" />:"Sign Up" }
            </Button>
          </Box>
          <p>
            Already a member <span onClick={clickEv}>Login</span>
          </p>
        </Box>
      </div>
    </div>
  </>
  );
};

export default SignupU;