import { Box, Input, TextField } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import Footer from "./Components/Footer";
import FullScreenLoader from "./Components/fullScreenLoader";
import Navbar from "./Components/Navbar";
import { Button } from "./styles/Button";

const Contact = () => {
  const Wrapper = styled.section`
    padding: 9rem 0 5rem 0;
    text-align: center;

    .container {
      margin-top: 6rem;

      .contact-form {
        max-width: 50rem;
        margin: auto;

        .contact-inputs {
          display: flex;
          flex-direction: column;
          gap: 3rem;

          input[type="submit"] {
            cursor: pointer;
            transition: all 0.2s;

            &:hover {
              background-color: ${({ theme }) => theme.colors.white};
              border: 1px solid ${({ theme }) => theme.colors.btn};
              color: ${({ theme }) => theme.colors.btn};
              transform: scale(0.9);

              .input{
                height:50vh
              }
            }
          }
        }
      }
    }
  `;
  const [loader, setLoader] = useState(true)
  setTimeout(() => {
    setLoader(false)
  }, 4000);

  return (

    <>
    {loader?(<FullScreenLoader />):(
      <>
      <Navbar />
    <Wrapper>
      <h2 className="common-heading">Contact Us</h2>

      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3618.727258563813!2d67.06045241447849!3d24.907283249514617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33f804e79559d%3A0xc8832f9bcbab59ed!2sModestile!5e0!3m2!1sen!2s!4v1670645919412!5m2!1sen!2s" 
      width="100%" 
      height="400"
      style={{border:'0'}} 
      allowFullScreen="" 
      loading="lazy" 
      referrerPolicy="no-referrer-when-downgrade"></iframe>

      <div className="container">
        <div className="contact-form">
          <form action="https://formspree.io/f/xaykrlgp" method="POST" className="contact-inputs">
            <input type="text" name="username" placeholder="user Name" required autoComplete="off" id="" />
            <input type="text" name="email" placeholder="Email" required autoComplete="off" id="" />
            <textarea name="msg" id="" cols="30" required autoComplete="off" placeholder="Enter Your Message" rows="10"></textarea>
             <Button>Submit</Button>
          </form>
        </div>
      </div>
    </Wrapper>
      <Footer />
      </>
    )}
    
    </>
  )
};

export default Contact;
