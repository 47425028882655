import React, { useContext } from 'react'
import { useState } from 'react'
import { Container } from 'react-bootstrap'
import Footer from './Components/Footer'
import FullScreenLoader from './Components/fullScreenLoader'
import HeroSection from './Components/HeroSection'
import Navbar from './Components/Navbar'
import { AppContext, useProductContext } from './context/productContext'

const About = () => {
  const [loader, setLoader] = useState(true)
  setTimeout(() => {
    setLoader(false)
  }, 4000);

  return (
    <>
    {loader?(<FullScreenLoader />):(
      <>
 <Navbar />
 <div style={{textAlign:'center',marginTop:30}}>
   <h2>About Us</h2>
   <h1>Modestile Islamic Collections</h1>
   <Container>
   <h3 style={{marginTop:20}}>With over 5 years of experience, Modestile continues its legacy of providing superior fabric caps, value for money and trendsettings islamic apparels to its consumers. with all over pakistan and internationally we supply our islamic products. We are expoter and supplier and maker of classy islamics products in our premium quality garments factory setup. we have 70+ caps designed in different different catogories of designed caps in many colors & fabrics.</h3>
   </Container>
 </div>
 <Footer />
 </>
    )}
   
    </>
  )
}

export default About
