import React, { useState } from 'react'
import styled from 'styled-components'
import {FaCheck}  from 'react-icons/fa'
import CardToggleAmount from './CardToggleAmount'
import { NavLink, useNavigate } from 'react-router-dom'
import { Button } from '../styles/Button'
import { useCartContext } from '../context/cartContext'

const AddToCart = ({product, cart}) => {
  const {addToCart} = useCartContext(); 
  console.log(product)
    const {id, name, price, image} = product
    const colors = [{
      colores:'gray',
      name:'gray'
    },
    {
      colores:'silver',
      name:'white'
    },
    {
      colores:'black',
      name:'Black'
    },
    {
      colores:'darkgray',
      name:'Dark Gray',
    },
    {
      colores:'blue',
      name:'Blue'
    },
    {
      colores:'#d08d03',
      name:'Golden'
    },
    {
      colores:'lightbrown',
      name:'Brown'
    },
    {
      colores:'lightgray',
      name:'Light Gray'
    },
    {
      colores:'#001233',
      name:'Ferozy Gray'
    },
    {
      colores:'#143601',
      name:'Naswari Green'
    },
    {
      colores:'#f8f9f8',
      name:'Fon'
    },
    {
      colores:'brown',
      name:'Mehendi'
    }
  ]


    const sizes = [{
      size:'50',
      name:'50'
    },
    {
      size:'51',
      name:'51'
    },
    {
      size:'52',
      name:'52'
    },
    {
      size:'53',
      name:'53',
    },
    {
      size:'54',
      name:'54'
    },
    {
      size:'55',
      name:'55'
    },
    {
      size:'56',
      name:'56'
    },
    {
      size:'57',
      name:'57'
    },
    {
      size:'58',
      name:'58'
    },
    {
      size:'59',
      name:'59'
    },
    {
      size:'60',
      name:'60'
    }
  ]
    const [color, setColors] = useState(colors[0])
    const [size, setSizes] = useState(sizes[0])
    const [amount, setAmount] = useState(1)
    const navigate = useNavigate()
    // console.log(cart)
    console.log(size)
    console.log(name)
    
    
    // console.log(stock)

    const setDecreament = ()=>{
      amount > 1? setAmount(amount -1): setAmount(1);
    } 
    
    const setIncreament = ()=>{
      amount < 1000? setAmount(amount +1): setAmount(1000);

    }
    return (
    <>
      <Wrapper>
        <div className="colors">
          <p>
            Colors: 
            {colors.map((x,i)=>{
              return(

                <button title={x.name} style={{backgroundColor:x.colores}} onClick={()=>setColors(x)} className={color.colores === x.colores? 'btnStyle active': 'btnStyle' } key={i}>
                  {color.colores == x.colores? <FaCheck  className='checkStyle'/>: null}
                </button>
              )
            })}
          </p>
          <p style={{marginTop:15}}>
            Size: 
            {sizes.map((x,i)=>{
              return(
                <button onClick={()=>setSizes(x)} className={size.size == x.size? 'active btnSize':'btnSize'}>{size.size === x.size? <FaCheck  className='checkStyle'/>: x.size}</button>
              )
            })}
          </p>
        </div>
        <CardToggleAmount 
        amount={amount}
        setIncreament={setIncreament}
        setDecreament={setDecreament}
        />

        <NavLink onClick={()=>addToCart(id,color.colores,amount,product,size.name)}>
          <Button>Add to Cart</Button>
        </NavLink>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.section`
  .colors p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  p:hover{
    color:black;
  }
  .btnStyle {
    width: 2rem;
    height: 2rem;
    background-color: #000;
    border-radius: 50%;
    margin-left: 1rem;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .btnSize {
    width: 2rem;
    height: 2rem;
    color: #edc531;
    background-color:white;
    margin-left: 1rem;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;
    border: 1px solid #edc531;
    &:hover {
      opacity: 1;
    }
  }
  .active {
    opacity: 1;
    background-color:#edc531;
    color:white;
  }
  .checkStyle {
    font-size: 1rem;
    color: #fff;
  }
  /* we can use it as a global one too  */
  .amount-toggle {
    margin-top: 3rem;
    margin-bottom: 1rem;
    display: flex;
    font-size: 1.4rem;
    button {
      border: none;
      background-color: #fff;
      cursor: pointer;
      padding-left:20px;
      padding-right:20px;
    }
    .amount-style {
      font-size: 2.4rem;
      color: ${({ theme }) => theme.colors.btn};
    }
  }
`;

export default AddToCart
